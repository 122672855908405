<template>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="headLine"> {{info.headLine}} </div>
        <div class="title"> {{info.title}} </div>
        <div class="text_box" v-for="item in info.list" :key="item">
          <div class="select_box"></div>
          <div class="text"> {{item.text}} </div>
        </div>
      </div>
      <img class="top_right" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_cflz_1.png">
    </div>
    <div class="bottom">
      <img src="https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/product_cflz_banner.png">
    </div>
  </div>
</template>

<script>

  export default {
    name: "product_cflz",
    data(){
      return{
        info:{
          headLine: '处方流转',
          title: '连接实体医院，将院内处方同步流转至院外指定的海南玉螺零售药房，' +
            '实现医院处方信息、医保结算信息与药品零售消费信息互联互通、实时共享。减轻医院「占药比」，实现处方流转管理规范化的同时，提升患者就医体验。',
          list: [
            {
              text: '响应政策，有效降低医院「占药比」。',
            },
            {
              text: '打通院内院外信息系统集成，形成诊疗服务闭环',
            },
            {
              text: '处方流转管理规范化，受监管部门监管，合法合规。',
            },
            {
              text: '以患者为核心，患者自主选择渠道取药，保障患者购药知情权和选择权。',
            },
            {
              text: '合理控制药品价格（跟标价），降低患者购药整体开支。',
            },
          ]
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    box-sizing border-box
    padding 0 140px
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    background #F5F5F7
    .top{
      display flex
      align-items center
      height 1175px
      position relative
      .top_left{
        width 774px
        display flex
        flex-flow column
        background #007BFF
        color #FFFFFF
        border-radius 24px
        position absolute
        box-sizing border-box
        padding 100px 50px
        z-index 666
        .headLine{
          font-size 56px
          line-height 67px
        }
        .title{
          font-size 20px
          line-height 34px
          /*margin-top 30px*/
          margin 30px 0 60px 0px
        }
        .text_box{
          display flex
          margin-top 14px
          .select_box{
            width 20px
            height 20px
            line-height 34px
            background #ffffff
          }
          .text{
            font-size 20px
            line-height 34px
            flex 1
            margin-left 10px
          }
        }
        .text_box:last-child{
          margin-top 0
        }
      }
      .top_right{
        width 1380px
        height 957px
        position absolute
        right 0
      }
    }
    .bottom{
      width 1640px
      height 845px
      margin 100px 0 195px 0
      img{
        width 100%
        height 100%
      }
    }
  }
</style>
